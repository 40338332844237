//Standard libraries
import { Action } from 'redux'
import { configureStore, PayloadAction } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
//Redux
import rootSaga from '../sagas/index'
import { persistReducers, loadState } from '../../localStorage'
import { productApi } from '../../features/product/query'
import { cmsApi } from '../../features/cms/query'
import { categoryApi } from '../../features/category/query'
import { plpApi } from '../../features/plp/query'
import { orderApi } from '../../features/order/query'
import { createWrapper } from 'next-redux-wrapper'
import { orderReturnApi } from '@features/orderReturn/query'
import { prescriptionApi } from '@features/prescription/query'
import { orderHistoryApi } from '@features/orderhistory/query'
import rootReducer from '@redux/rootReducer'
import { roxApi } from '@features/rox/roxApi'
import { healthFundApi } from '@features/healthfund/query'
import { tabApi } from '@features/stores/query'
import { newsletterApi } from '@features/newsletter/query'
import { vmApi } from '@features/virtualMirror/query'
import { fiscalCodeApi } from '@features/user/query'
import { seoApi } from '@features/seo/query'
import { reorderApi } from '@features/reorder/query'
import { subscriptionApi } from '@features/subscription/query'
import { bffProductApi } from '@features/bffProduct/query'
import { liveStockCheckApi } from '@features/liveStockCheck/query'
import { onBehalfApi } from '@components/TopBar/api/query'
import { customerSegmentApi } from '@features/customerSegments/query'
import { appListenerMiddleware } from '@redux/listener/listener'

const persistedState = loadState()
const sagaMiddleware = createSagaMiddleware()

const makeStore = () => {
  const s = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: true,
        serializableCheck: false,
        thunk: true,
      }).concat(
        prescriptionApi.middleware,
        orderHistoryApi.middleware,
        roxApi.middleware,
        orderApi.middleware,
        productApi.middleware,
        liveStockCheckApi.middleware,
        bffProductApi.middleware,
        cmsApi.middleware,
        seoApi.middleware,
        categoryApi.middleware,
        plpApi.middleware,
        orderReturnApi.middleware,
        healthFundApi.middleware,
        tabApi.middleware,
        newsletterApi.middleware,
        vmApi.middleware,
        fiscalCodeApi.middleware,
        reorderApi.middleware,
        subscriptionApi.middleware,
        onBehalfApi.middleware,
        customerSegmentApi.middleware,
        appListenerMiddleware.middleware,
        sagaMiddleware
      ),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: persistedState,
    enhancers: [],
  })
  sagaMiddleware.run(rootSaga)
  return s
}

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<typeof rootReducer>
export type AppDispatch = AppStore['dispatch']

type ExtraProps = {}

export type AppPayloadWithWidget<T = ExtraProps> = { widget: string } & T
export type AppPayloadAction<P = ExtraProps, T extends string = string> = PayloadAction<P, T>
export type AppResponseAction<R = ExtraProps, T = string> = Action<T> & {
  response: R
}
export type AppPayloadWithWidgetAction<P = ExtraProps, T extends string = string> = AppPayloadAction<
  AppPayloadWithWidget<P>,
  T
>

export const initialStore = makeStore()
const wrapper = createWrapper<AppStore>(() => initialStore, { debug: false })
persistReducers(initialStore)

export default wrapper
