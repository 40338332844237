import { GUEST_LOGIN_SUCCESS_ACTION, LOGIN_SUCCESS_ACTION, LOGOUT_SUCCESS_ACTION } from '@redux/actions/user'
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import { customerSegmentApi } from '../../features/customerSegments/query'
import guestIdentityService from '@foundation/apis/transaction/guestIdentity.service'
import { EARLY_ACCESS_PROMO } from '@constants/common'
import { isEarlyAccesFeatureEnabled } from '@utils/common'
import { isEmpty } from 'lodash-es'

export const appListenerMiddleware = createListenerMiddleware()

appListenerMiddleware.startListening({
  matcher: isAnyOf(
    LOGIN_SUCCESS_ACTION,
    customerSegmentApi.endpoints.getCampaignAccess.matchFulfilled,
    customerSegmentApi.endpoints.getPersonalizationId.matchFulfilled
  ),
  effect: async (_action, listenerApi) => {
    setTimeout(() => {
      listenerApi.dispatch(customerSegmentApi.util.invalidateTags(['CustomerSegment']))
    }, 1)
  },
})

appListenerMiddleware.startListening({
  matcher: isAnyOf(LOGOUT_SUCCESS_ACTION),
  effect: async (_action, listenerApi) => {
    // TODO: better type than any
    const guestResponse: any = await guestIdentityService.login({})
    listenerApi.dispatch(GUEST_LOGIN_SUCCESS_ACTION(guestResponse.data))

    // NOTE: if early access is not enabled we want to trigger customer segment request by clearing segment cache
    if (!isEarlyAccesFeatureEnabled()) {
      setTimeout(() => {
        listenerApi.dispatch(customerSegmentApi.util.invalidateTags(['CustomerSegment']))
      }, 1)
    }

    // NOTE: if early access is enabled, customer segment request is not needed here as it will be triggered if getCampaignAccess is fulfilled
    if (isEarlyAccesFeatureEnabled() && !isEmpty(guestResponse.data)) {
      listenerApi.dispatch(
        customerSegmentApi.endpoints.getCampaignAccess.initiate({
          personalizationId: guestResponse.data?.personalizationId,
          storeId: guestResponse.data?.storeId,
          campaignName: EARLY_ACCESS_PROMO,
        })
      )
    }
  },
})
