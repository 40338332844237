import { productApi } from '../features/product/query'
import { cmsApi } from '../features/cms/query'
import { orderApi } from '../features/order/query'
import {
  ApiReducerState,
  ConfirmationReducerState,
  ContextReducerState,
  ContractReducerState,
  ErrorReducerState,
  OrderDetailsReducerState,
  OrganizationReducerState,
  ProductTypeFiltersState,
  SearchReducerState,
  SiteReducerState,
  SubscriptionReducerState,
  ThemeReducerState,
  UserPrescriptionState,
  UserReducerState,
} from './reducers/reducerStateInterface'
import account, { IAccountState } from '../features/account'
import preview, { PreviewState } from '../features/preview/slice'
import success, { ISuccessSliceState } from '../features/success/slice'
import wishList, { WishListState } from '../features/wishList/slice'
import plp, { PlpStateInterface } from '../features/plp/slice'
import stores, { StoresState } from '../features/stores/slice'
import product, { ProductApiState, ProductState } from '../features/product/slice'
import ui, { IUiState } from '../features/ui/slice'
import { cmsSlice, ICmsState } from '../features/cms/slice'
import seo, { SeoState } from '../features/seo/slice'
import { IOrderSliceState } from '../features/order/IOrderSliceState'

//Standard libraries
import { combineReducers } from 'redux'
import confirmation from './reducers/confirmation'
import api from './reducers/api'
import context from './reducers/context'
import contract from './reducers/contract'
import error from './reducers/error'
import order, { OrderApiState } from '../features/order/slice'
import orderDetails from './reducers/orderDetails'
import organization from './reducers/organization'
import search from './reducers/search'
import site from './reducers/site'
import subscriptionConfig from './reducers/subscription'
import theme from './reducers/theme'
import user from './reducers/user'
import { plpApi } from '../features/plp/query'
import { categoryApi } from '../features/category/query'
import { prescriptionApi } from '../features/prescription/query'
import { orderHistoryApi } from '../features/orderhistory/query'
import { roxApi } from '../features/rox/roxApi'
import { newsletterApi } from '../features/newsletter/query'
import { cartuiSlice, CartUIState } from '../features/cartui/cartuiSlice'
import { roxSlice, RoxState } from '../features/rox/roxSlice'
import { orderReturnApi } from '@features/orderReturn/query'
import { orderReturnSlice } from '@features/orderReturn/slice'
import { OrderReturnState } from '@typesApp/orderReturn'
import { healthFundApi } from '../features/healthfund/query'

import productTypeFilters from './reducers/productTypeFilters'
import { prescriptionSlice } from '../features/prescription/prescriptionSlice'
import { PrescriptionSliceState } from '@features/prescription/PrescriptionSliceState'
import frameGenius, { IStateFrameGenuis } from '../features/frameGenius/slice'
import { tabApi } from '@features/stores/query'
import { vmApi } from '@features/virtualMirror/query'
import { fiscalCodeApi } from '@features/user/query'
import { seoApi } from '@features/seo/query'
import { IReorderState, reorderApi } from '@features/reorder/query'
import { subscriptionApi } from '@features/subscription/query'
import { bffProductApi } from '@features/bffProduct/query'
import { liveStockCheckApi } from '@features/liveStockCheck/query'
import { checkoutReducer, checkoutSlice, CheckoutState } from '@features/checkout/slice'
import { onBehalfApi } from '@components/TopBar/api/query'
import { customerSegmentApi } from '@features/customerSegments/query'
import { onBehalfSlice, OnBehalfState } from '@features/onbehalf/slice'
export * from './reducers/reducerStateInterface'

export interface RootReducerState {
  account: IAccountState
  api: ApiReducerState
  user: UserReducerState
  order: IOrderSliceState
  orderApi: OrderApiState
  prescriptionApi: UserPrescriptionState
  prescriptions: UserPrescriptionState
  error: ErrorReducerState
  seo: SeoState
  contract: ContractReducerState
  search: SearchReducerState
  organization: OrganizationReducerState
  context: ContextReducerState
  success: ISuccessSliceState
  confirmation: ConfirmationReducerState
  orderDetails: OrderDetailsReducerState
  orderDetailsLoadings: { [orderId: string]: boolean }
  theme: ThemeReducerState
  site: SiteReducerState
  subscriptionConfig: SubscriptionReducerState
  wishList: WishListState
  preview: PreviewState
  product: ProductState
  cmsApi: ICmsState
  productApi: ProductApiState
  plp: PlpStateInterface
  ui: IUiState
  stores: StoresState
  orderReturnApi: OrderReturnState
  orderReturn: OrderReturnState
  cartui: CartUIState
  rox: RoxState
  productTypeFilters: ProductTypeFiltersState
  prescription: PrescriptionSliceState
  cms: ICmsState
  frameGenius: IStateFrameGenuis
  reorderApi: IReorderState
  checkout: CheckoutState
  onBehalf: OnBehalfState
}

const reducers = {
  account,
  api,
  user,
  order,
  error,
  contract,
  search,
  context,
  organization,
  success,
  confirmation,
  orderDetails,
  theme,
  site,
  subscriptionConfig,
  wishList,
  preview,
  plp,
  ui,
  stores,
  productTypeFilters,
  product,
  seo,
  frameGenius,
  [productApi.reducerPath]: productApi.reducer,
  [liveStockCheckApi.reducerPath]: liveStockCheckApi.reducer,
  [bffProductApi.reducerPath]: bffProductApi.reducer,
  [cmsApi.reducerPath]: cmsApi.reducer,
  [seoApi.reducerPath]: seoApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [orderHistoryApi.reducerPath]: orderHistoryApi.reducer,
  [roxApi.reducerPath]: roxApi.reducer,
  [newsletterApi.reducerPath]: newsletterApi.reducer,
  [prescriptionApi.reducerPath]: prescriptionApi.reducer,
  [plpApi.reducerPath]: plpApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [healthFundApi.reducerPath]: healthFundApi.reducer,
  [tabApi.reducerPath]: tabApi.reducer,
  [vmApi.reducerPath]: vmApi.reducer,
  [fiscalCodeApi.reducerPath]: fiscalCodeApi.reducer,
  [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  [reorderApi.reducerPath]: reorderApi.reducer,
  [orderReturnApi.reducerPath]: orderReturnApi.reducer,
  [orderReturnSlice.name]: orderReturnSlice.reducer,
  [cartuiSlice.name]: cartuiSlice.reducer,
  [roxSlice.name]: roxSlice.reducer,
  [prescriptionSlice.name]: prescriptionSlice.reducer,
  [cmsSlice.name]: cmsSlice.reducer,
  [checkoutSlice.name]: checkoutReducer,
  [onBehalfApi.reducerPath]: onBehalfApi.reducer,
  [onBehalfSlice.name]: onBehalfSlice.reducer,
  [customerSegmentApi.reducerPath]: customerSegmentApi.reducer,
}

const rootReducer = combineReducers(reducers)

export default rootReducer
