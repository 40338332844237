import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SuggestedFeatures } from '../../types/FrameGenius/frameGenius'
import { OnBehalfState } from '@features/onbehalf/slice'

export interface IStateFrameGenuis {
  areScriptsLoaded: boolean
  isSizeAdvisorOpen: boolean
  isFrameAdvisorOpen: boolean
  analysisResults: SuggestedFeatures
}

export const initialStateFrameGenuis: IStateFrameGenuis = {
  areScriptsLoaded: false,
  isSizeAdvisorOpen: false,
  isFrameAdvisorOpen: false,
  analysisResults: {
    glassGender: [],
    frameShape: [],
    frameColor: [],
    frameColorPalette: [],
    templeThickness: [],
  },
}

export const initStateOnBehalf: OnBehalfState = {
  isActive: false,
}

export const frameGeniusSlice = createSlice({
  name: 'frameGenius',
  initialState: initialStateFrameGenuis,
  reducers: {
    setLoadedScripts: (state: IStateFrameGenuis, action: PayloadAction<boolean>) => {
      state.areScriptsLoaded = action.payload
    },
    setSizeAdvisorOpen: (state: IStateFrameGenuis, action: PayloadAction<boolean>) => {
      state.isSizeAdvisorOpen = action.payload
    },
    setFrameAdvisorOpen: (state: IStateFrameGenuis, action: PayloadAction<boolean>) => {
      state.isFrameAdvisorOpen = action.payload
    },
    setAnalysisResults: (state: IStateFrameGenuis, action: PayloadAction<SuggestedFeatures>) => {
      state.analysisResults = action.payload
    },
  },
})

export const { setLoadedScripts, setSizeAdvisorOpen, setFrameAdvisorOpen, setAnalysisResults } =
  frameGeniusSlice.actions

export default frameGeniusSlice.reducer
