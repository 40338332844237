import {
  AccountReducerState,
  ApiReducerState,
  ContextReducerState,
  ErrorReducerState,
  OrderDetailsReducerState,
  ProductTypeFiltersState,
  SearchReducerState,
  ThemeReducerState,
  UserPrescriptionState,
  SubscriptionReducerState,
} from './reducerStateInterface'
import { IOrderSliceState } from '../../features/order/IOrderSliceState'

import { RootReducerState } from '../rootReducer'
import { WishListState } from '../../features/wishList/slice'
import { PreviewState } from '../../features/preview/slice'
import { ProductApiState, ProductState } from '../../features/product/slice'
import { initialPlpState } from '../../features/plp/slice'
import { ICmsState, initialCmsState } from '../../features/cms/slice'
import { initialStateUi } from '../../features/ui/slice'
import { OrderApiState } from '../../features/order/slice'
import { initialStoresState } from '../../features/stores/slice'
import { cartuiSliceInitialState } from '../../features/cartui/cartuiSlice'
import { roxSliceInitialState } from '../../features/rox/roxSlice'
import { initialStateFrameGenuis, initStateOnBehalf } from '@features/frameGenius/slice'
import { PrescriptionSliceState } from '@features/prescription/PrescriptionSliceState'
import { IReorderState } from '@features/reorder/query'
import { OrderReturnState } from '@typesApp/orderReturn'

const accountDefaultState: AccountReducerState = {
  isFetching: false,
  orders: null,
}

const apiDefaultState: ApiReducerState = {
  apiFlowList: [],
}

export const PLP_DEFAULT_SORT_OPTION = {
  value: '',
  profileName: 'LX_findProductsByCategory_Grouping',
}

const errorDefaultState: ErrorReducerState = {
  errorKey: null,
  errorCode: null,
  errorTitleKey: null,
  errorMsgKey: null,
  handled: null,
  errorMessage: '',
  errorParameters: '',
}

const productTypeFilters: ProductTypeFiltersState = {
  values: null,
  searchTerm: '',
}

const prescriptionDefaultState: UserPrescriptionState = {
  code: '',
  userId: 0,
  errorMessage: '',
  prescription: [],
  recordCount: 0,
}

const orderDefaultState: IOrderSliceState = {
  isAddingItem: false,
  isFetching: false,
  cart: undefined,
  numItems: 0,
  orderItems: [],
  catentries: undefined,
  parsedOrderItems: {
    rx: null,
    cl: null,
    'cl-acc': null,
    sun: null,
    default: null,
  },
  isCheckoutDisabled: false,
  shipInfos: undefined,
  payMethods: [],
  isRecurringOrderDisabled: false,
  isFetchingShippingInfo: false,
  isShippingUsedAsBilling: true,
  isPromoCouponApplied: false,
  shippingFormStatus: {
    isValid: false,
  },
  billingFormStatus: {
    isValid: false,
  },
  paypalExpress: {
    isSelected: false,
    loading: false,
    redirecturl: '',
    decision: '',
    orderId: '',
    error: true,
  },
  applePayInfo: {
    flow: 'classic',
  },
  orderComplete: false,
  orderDetails: null,
  isFinalizing: false,
  isFinalizationFailed: false,
  selectedPayMethodInfoList: [],
  isRXProductsLimitExceeded: false,
  usePrescription: {
    selectedMacroIndex: 0,
    prescriptionMacroGroups: [],
    prescriptionFormData: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      file: '',
    },
  },
  cartShippingCharges: {
    shippingCharges: undefined,
    error: undefined,
  },
}

const orderDetailsInitState: OrderDetailsReducerState = {
  data: {},
  loadings: [],
  invalidOrders: [],
}

const searchDefaultState: SearchReducerState = {
  keywords: [],
}

const prescriptionsDefaultState: UserPrescriptionState = {
  code: '',
  userId: 0,
  prescription: [],
  recordCount: 0,
}

const themeDefaultState: ThemeReducerState = {
  themeMode: 'light',
}

const orderReturnDefaultState: OrderReturnState = {
  orderId: '',
  checkedItems: [],
  labelType: '',
  isFetchingReturnReasons: true,
}

export const subscriptionDefaultState: SubscriptionReducerState = {
  enabled: false,
}

const defaultStates: RootReducerState = {
  account: accountDefaultState,
  api: apiDefaultState,
  confirmation: { key: '', title: '' },
  context: {} as ContextReducerState,
  prescriptionApi: prescriptionDefaultState,
  prescriptions: prescriptionsDefaultState,
  contract: {},
  error: errorDefaultState,
  order: orderDefaultState,
  orderDetails: orderDetailsInitState,
  orderDetailsLoadings: {},
  organization: { entitledOrganizations: null, organizationDetails: null },
  success: { key: '' },
  seo: {},
  search: searchDefaultState,
  site: { currentSite: null, hostnameUrls: {}, hostname: '' },
  subscriptionConfig: subscriptionDefaultState,
  theme: themeDefaultState,
  user: {
    initiatedFromStorage: false,
    WCToken: '',
    WCTrustedToken: '',
    details: null,
    areDetailsLoading: false,
    userId: '',
    userLoggedIn: false,
  },
  wishList: {} as WishListState,
  preview: {} as PreviewState,
  product: {} as ProductState,
  productApi: {} as ProductApiState,
  orderApi: {} as OrderApiState,
  cmsApi: {} as ICmsState,
  reorderApi: {} as IReorderState,
  plp: initialPlpState,
  ui: initialStateUi,
  stores: initialStoresState,
  productTypeFilters: productTypeFilters,
  orderReturn: orderReturnDefaultState,
  orderReturnApi: orderReturnDefaultState,
  cartui: cartuiSliceInitialState(),
  rox: roxSliceInitialState(),
  prescription: {} as PrescriptionSliceState,
  cms: initialCmsState,
  frameGenius: initialStateFrameGenuis,
  onBehalf: initStateOnBehalf,
  checkout: {
    isNewsletterSubscriptionRequested: false,
  },
}

const clearState = (o: any) => {
  for (let variableKey in o) {
    if (o.hasOwnProperty(variableKey)) {
      delete o[variableKey]
    }
  }
}

const initStates = {
  ...defaultStates,
}

export { clearState, defaultStates, orderDetailsInitState }

export default initStates
